/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  height: 70px;
  margin: 11px 0 11px 3.7414966%;
}
.section--multimood {
  margin-top: 92px;
}
.cbdModule--teaserTitle {
  width: 95.23809524%;
  margin-left: 2.38095238%;
}
.cbdModule--teaserTitle .teaserTitle {
  width: 97.14285714%;
  margin-left: 1.42857143%;
  margin-right: 1.42857143%;
}
.area,
.fakeArea {
  width: 100%;
}
.area > .unit,
.fakeArea > .unit,
.area > .fakeUnit,
.fakeArea > .fakeUnit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot,
.fakeArea .foot {
  margin-right: 1.42857143%;
  margin-left: 1.42857143%;
}
.area .part,
.fakeArea .part,
.area .fakePart,
.fakeArea .fakePart {
  margin-right: 1.42857143%;
  margin-left: 1.42857143%;
  width: 97.14285714%;
}
.area .tiny,
.fakeArea .tiny {
  width: 47.14285714%;
}
.area > .slim,
.fakeArea > .slim {
  width: 50%;
}
.area > .slim .foot,
.fakeArea > .slim .foot,
.area > .slim .part,
.fakeArea > .slim .part,
.area > .slim .fakePart,
.fakeArea > .slim .fakePart {
  margin-right: 2.85714286%;
  margin-left: 2.85714286%;
}
.area > .slim .part,
.fakeArea > .slim .part,
.area > .slim .fakePart,
.fakeArea > .slim .fakePart {
  width: 94.28571429%;
}
.area > .slim .tiny,
.fakeArea > .slim .tiny {
  width: 94.28571429%;
}
.content--norm .area,
.content--norm .fakeArea {
  width: 95.23809524%;
  margin-left: 2.38095238%;
}
.cb-layout4 .content--norm .area {
  width: 92.5170068%;
  margin-left: 3.7414966%;
}
.content--norm .area .pure.wide .tall,
.content--norm .fakeArea .pure.wide .tall {
  box-sizing: border-box;
  padding-left: 16.9047619%;
}
.content--norm .area .seam.slim {
  width: 33.33333333%;
}
.content--norm .area .seam.slim .part {
  margin-left: 4.28571429%;
  margin-right: 4.28571429%;
  width: 91.42857143%;
}
.content--norm .area .flat,
.content--norm .area .edge {
  margin-left: 1.42857143%;
  margin-right: 1.42857143%;
}
.content--norm .area .flat.wide,
.content--norm .area .edge.wide {
  width: 97.14285714%;
}
.content--norm .area .flat.wide .part,
.content--norm .area .edge.wide .part {
  margin-left: 2.45098039%;
  margin-right: 2.45098039%;
  width: 95.09803922%;
}
.content--norm .area .flat.wide .part.tiny,
.content--norm .area .edge.wide .part.tiny {
  width: 44.9754902%;
}
.content--norm .area .flat.slim,
.content--norm .area .edge.slim {
  width: 30.47619048%;
}
.content--norm .area .flat.slim .part,
.content--norm .area .edge.slim .part {
  margin-left: 7.8125%;
  margin-right: 7.8125%;
  width: 84.375%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */